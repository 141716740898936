import React from 'react'

import { List } from './list'
import { SubTitle } from './sub-title'
import { TitleBar } from './title-bar'
import { BackToTop } from './back-to-top'

import './query.fragment'

export function Catalog(props) {
  return <div {...props} />
}

Catalog.BackToTop = BackToTop
Catalog.List = List
Catalog.SubTitle = SubTitle
Catalog.TitleBar = TitleBar
