import PropTypes from 'prop-types'
import React from 'react'

import { Link } from '../link'

import * as styles from './title-bar.module.css'

export function TitleBar(props) {
  const { children, showContactInfo = false, ...rest } = props

  return (
    <div className={styles.titleBar} {...rest}>
      <h1 id="top" className={styles.title}>
        {children}
      </h1>

      {showContactInfo && (
        <div className={styles.contact}>
          <Link to="/contact-us">Contact Us</Link> <span>For Pricing</span>
        </div>
      )}
    </div>
  )
}
TitleBar.propTypes = {
  children: PropTypes.node.isRequired,
  showContactInfo: PropTypes.bool,
}
