import cx from 'classnames'
import { Link as GatsbyLink, navigate } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import * as styles from './index.module.css'

export function Link(props) {
  const className = cx(styles.link, props.className)
  return <GatsbyLink {...props} className={className} />
}
Link.propTypes = { className: PropTypes.string }

export function LinkPrevious(props) {
  const { children, ...rest } = props
  const className = cx(styles.link, props.className)

  const back = useCallback((evt) => {
    evt.preventDefault()
    evt.stopPropagation()

    navigate(-1)
  }, [])

  return (
    <a
      onClick={back}
      onKeyPress={back}
      role="link"
      tabIndex={0}
      {...rest}
      className={className}
    >
      {children}
    </a>
  )
}

LinkPrevious.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}
