import cx from 'classnames'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React, { useMemo } from 'react'

import * as styleUtils from '../../global/utils.module.css'
import * as styles from './list.module.css'

export function List(props) {
  return <ul className={styles.list} {...props} />
}

export function ListHeader(props) {
  const { children, ...rest } = props

  return (
    <li {...rest}>
      <div className={styles.itemInner}>{children}</div>
    </li>
  )
}
ListHeader.propTypes = {
  children: PropTypes.node,
}

List.Header = ListHeader

export function ListItem(props) {
  const { product, renderItem = DefaultRenderItem, ...rest } = props
  const RenderItem = useMemo(() => renderItem, [renderItem])

  return (
    <li className={styles.item} {...rest}>
      <RenderItem className={styles.itemInner}>
        <div className={styles.itemThumbnail}>
          <img
            alt={product.name}
            src={R.pathOr('', ['image', 'secure_url'], product).replace(
              '/upload/',
              '/upload/w_225/',
            )}
          />
        </div>

        <div className={styles.itemDetails}>
          <h3 className={cx(styleUtils.truncate, styles.itemName)}>
            {product.name}
          </h3>

          <p className={cx(styleUtils.truncate, styles.itemNumber)}>
            {product.number}
          </p>
        </div>
      </RenderItem>
    </li>
  )
}
ListItem.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
    number: PropTypes.string,
    image: PropTypes.any.isRequired,
    urlSlug: PropTypes.string.isRequired,
  }).isRequired,
  renderItem: PropTypes.func,
}
List.Item = ListItem

function DefaultRenderItem(props) {
  const { children, ...rest } = props
  return <span {...rest}>{children}</span>
}

DefaultRenderItem.propTypes = { children: PropTypes.node }
