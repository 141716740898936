import React from 'react'

import * as styles from './back-to-top.module.css'

export function BackToTop() {
  return (
    <a href="#top" className={styles.backToTop}>
      <svg viewBox="0 0 100 100">
        <g>
          <path d="M88.051,80.544C86.519,82.077 84.07,82.077 82.536,80.544L50,48.007L17.464,80.544C15.932,82.077 13.481,82.077 11.949,80.544L1.778,70.435C0.246,68.904 0.246,66.391 1.778,64.858L47.242,19.456C48.773,17.924 51.225,17.924 52.758,19.456L98.224,64.858C99.753,66.391 99.753,68.904 98.224,70.435L88.051,80.544Z" />
        </g>
      </svg>
      Back to Top
    </a>
  )
}
