import PropTypes from 'prop-types'
import React from 'react'

import * as styles from './sub-title.module.css'

export function SubTitle(props) {
  const { children, ...rest } = props

  return (
    <h2 className={styles.subTitle} {...rest}>
      {children}
    </h2>
  )
}
SubTitle.propTypes = {
  children: PropTypes.node.isRequired,
}
