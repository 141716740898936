import axios from 'axios'
import * as R from 'ramda'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import queryString from 'query-string'

import { MainLayout } from '../../layouts/main'
import { Link } from '../../components/link'
import { Catalog } from '../../components/catalog'
import { SectionTitle } from '../../components/section-title'

const url = `${process.env.GATSBY_OLD_API_URL}/search`

const getCategories = R.compose(
  //
  R.sortBy((x) => x.name),
  R.uniqBy((x) => x.id),
  R.pluck('category'),
  R.propOr([], 'nodes'),
  R.propOr({}, 'data'),
)

const getProducts = (category) =>
  R.compose(
    //
    R.pluck('product'),
    R.prop(category),
    R.groupBy((x) => x.category.name),
    R.propOr([], 'nodes'),
    R.propOr({}, 'data'),
  )

const Results = ({ term }) => {
  const searchTerm = term || ''
  const [searchResults, setSearchResults] = useState([])
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState([])

  useEffect(() => {
    const search = async () => {
      setLoading(true)
      try {
        const results = await axios.post(url, { term: searchTerm })

        setSearchResults(results)
      } catch (err) {
        setError('Unable to search at this time')
      }
      setLoading(false)
    }

    search()
  }, [searchTerm])

  const categories = getCategories(searchResults)

  return (
    <>
      <SectionTitle>Search All Results</SectionTitle>
      <Catalog>
        {error && <p>Encountered an Error</p>}
        {loading && <p>Loading</p>}

        {searchResults &&
          searchResults.data &&
          searchResults.data.nodes &&
          searchResults.data.nodes.length === 0 && (
            <p>No results found for term "{searchTerm}"</p>
          )}

        {!searchTerm && !searchResults && (
          <p>Please search add a search term</p>
        )}

        {categories && (
          <div>
            {R.map(
              (category) => (
                <div key={category.id}>
                  <Catalog.SubTitle>{category.name}</Catalog.SubTitle>
                  <Catalog.List>
                    {R.map((prod) => {
                      const path = `/catalog/${
                        category.urlSlug || category.url_slug
                      }/${prod.urlSlug || prod.url_slug}`
                      const RenderItem = (p) => <Link to={path} {...p} />

                      return (
                        <Catalog.List.Item
                          key={prod.id}
                          renderItem={RenderItem}
                          product={prod}
                        />
                      )
                    }, getProducts(category.name)(searchResults))}
                  </Catalog.List>
                </div>
              ),
              categories,
            )}
          </div>
        )}
      </Catalog>
    </>
  )
}

Results.propTypes = {
  term: PropTypes.string.isRequired,
}

export default function SearchPage({ location }) {
  const { term } = queryString.parse(location.search)
  const searchTerm = term || ''
  return (
    <MainLayout>
      <Results term={searchTerm} />
    </MainLayout>
  )
}

SearchPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
}
